<template>
  <div class="sm:flex gap-8">
    <div>
      <img style="max-height: 400px; width: 200px" :src="imgScr" />
    </div>
    <div>
      <h2 class="rfs-40">{{ heading }}</h2>
      <p class="" v-if="explanation" style="max-width: 800px">
        {{ explanation }}
      </p>
      <p v-if="this.$route.name === 'OldPage'" style="max-width: 800px">
        You will be forward in {{ seconds }} seconds. Please update your
        bookmarks and links with the new page URL. If you are not forwarded,
        <router-link
          :to="{ name: $route.meta.newLocationName, params: $route.params }"
          >click here to be forwarded to the new page.</router-link
        >
      </p>
      <p v-else>
        Click the browser back button or try
        <router-link to="/">returning to the application home page</router-link
        >.
      </p>
    </div>
  </div>
</template>

<script>
  // must import so webpack can build dynamic route when using image scr var
  import ToTheRescue from '../../../assets/img/toTheRescue02.svg';
  import YouMayNotPass from '../../../assets/img/youMayNotPass02.svg';

  export default {
    name: 'NothingToSee',
    data() {
      return {
        heading: null,
        explanation: null,
        imgScr: null,
        seconds: 12
      };
    },
    mounted() {
      switch (this.$route.name) {
        case 'OldPage':
          this.heading = 'Looks like this page has been moved.';
          this.imgScr = ToTheRescue;

          console.log(this.$router);
          var _this = this;
          console.log({
            name: _this.$route.meta.newLocationName,
            params: _this.$route.params
          });
          var countdown = setInterval(function () {
            if (_this.seconds <= 0) {
              clearInterval(countdown);
              _this.$router.push({
                name: _this.$route.meta.newLocationName,
                params: _this.$route.params
              });
            }
            if (_this.seconds !== 0) {
              _this.seconds--;
            }
          }, 1000);
          break;
        case 'NotFound':
          this.heading = 'Looks like you need to be rescued from a 404 error!';
          this.explanation =
            'These errors are scary, but you’re safe with us. All it means is you might be lost or we’ve moved the page you are looking for. But never fear! You are just one click away from returning home.';
          this.imgScr = ToTheRescue;
          break;
        case 'Unauthorized':
          this.heading = 'Stop! You have wondered into a secured area.';
          this.explanation =
            'Please return to your designated area or ask your administrator for premissions to enter this area.';
          this.imgScr = YouMayNotPass;
          break;
        default:
          this.heading = 'Looks like something has gone wrong.';
          this.explanation =
            'To be honest, we’re not sure what happened. But chances are, you are one step away from getting back to a familiar place.';
          this.imgScr = ToTheRescue;
      }
    }
  };
</script>
